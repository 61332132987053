import { getCommonParamsOptions } from '@/utils/common.js'
export const baseViewFormConfig = {
  formItems: [
    {
      field: 'name',
      type: 'input',
      label: 'param.param-name',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'paramType',
      type: 'select',
      label: 'param.param-value-type',
      isResolveGlobalParams: true,
      options: [],
      handler: () => getCommonParamsOptions('value_type'),
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'defaultValue',
      type: 'input',
      label: 'param.default-value',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'tenantId',
      type: 'input',
      label: 'user.tenant',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'status',
      type: 'select',
      label: 'general.status',
      isResolveGlobalParams: true,
      options: [],
      handler: () => getCommonParamsOptions('status'),
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'description',
      type: 'input',
      label: 'general.description',
      otherOptions: {
        disabled: true
      }
    }
  ]
}
