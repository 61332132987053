<template>
  <div class="base-form">
    <page-form
      ref="baseFormRef"
      v-bind="configOptions"
      @handleNewClick="handleSubmit"
      :pageInfo="pageInfo"
    >
    </page-form>
  </div>
</template>
<script setup>
import pageForm from '@/components/PageForm/page-form.vue'
import {
  baseNewFormConfig,
  baseUpdateFormConfig,
  baseViewFormConfig
} from './config/index'
import { ref, getCurrentInstance, computed, onMounted, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import i18n from '@/i18n'
import { trueFalseOption } from '@/utils/constant/const.js'

const routes = useRoute()
const router = useRouter()
const { t } = i18n.global
// 1、预定义当前页面的map映射数组
const pageMapArray = [
  {
    // view routesName 跟你配置路由的name相关
    routesName: 'ParamView',
    baseFormConfig: baseViewFormConfig,
    pageTitle: t('param.param-view'),
    pageType: 'view'
  },
  {
    routesName: 'ParamNew',
    baseFormConfig: baseNewFormConfig,
    pageTitle: t('param.param-new'),
    pageType: 'add'
  },
  {
    routesName: 'ParamEdit',
    baseFormConfig: baseUpdateFormConfig,
    pageTitle: t('param.param-edit'),
    pageType: 'edit',
    isResetFields: false
  }
]
// 2、根据当前路由对象中的name属性判断当前页面
const currentPageConfig = pageMapArray.find((item) => {
  if (item.routesName === routes.name) return item
})

const store = useStore()

// 3、将需要用到的属性解构出来
const { baseFormConfig, pageType } = currentPageConfig

const baseFormConfigRef = computed(() => {
  return baseFormConfig
})

// 4、区分当前页面是否需要转化options选项
const configOptions = ref({})

currentPageConfig.baseFormConfig = baseFormConfigRef
configOptions.value = currentPageConfig

const id = routes.params.id

const { proxy, appContext } = getCurrentInstance()
const pageInfo = ref({})
const handleMessageCommit =
  appContext.config.globalProperties.$handleMessageCommit
const bus = appContext.config.globalProperties.$bus

const baseFormRef = ref()
const handleChange = async (form) => {
  const formData = baseFormRef.value.formData

  const { fieldName } = form
  if (fieldName === 'paramType') {
    const tenantItem = baseFormConfigRef.value.formItems.find(
      (item) => item.field === 'defaultValue'
    )
    if (form.paramType === '02') {
      tenantItem.type = 'select'
      tenantItem.options = trueFalseOption
    } else {
      tenantItem.type = 'input'
    }
    formData.defaultValue = ''
  }
}

onMounted(() => {
  bus.on('linkageOptionsChange', handleChange)
})

onUnmounted(() => {
  // 组件销毁前注销事件监听
  bus.off('linkageOptionsChange', handleChange)
})

// 5、定义方法获取当前页面的初始化数据
const getCurrentPageInfo = async () => {
  const paramInfo = await store.dispatch('param/handleGetParam', { id })

  if (JSON.stringify(paramInfo) !== '{}') {
    paramInfo.createdTime = proxy.$filters.fmtDateTime(paramInfo.createdTime)
    paramInfo.tenantId = store.getters.tenantDataMap[paramInfo?.tenantId]
    pageInfo.value = paramInfo
  }
}

// 6、判断当前页是否需要调用getCurrentPageInfo
if (['view', 'edit'].includes(pageType)) {
  getCurrentPageInfo()
} else {
  pageInfo.value = {}
}

// 7、定义被提交的方法
const handleSubmit = async (data) => {
  let url = 'handleParamAction'
  const form = { ...data }
  if (pageType === 'edit') {
    url = 'handleUpdateParamSubmit'
    form.id = id
  }
  handleMessageCommit(`param/${url}`, form).then(() => {
    router.push('/parameter/overview')
  })
}
</script>

<style scoped></style>
